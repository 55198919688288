import React, { useEffect, useState, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import queryString from "query-string";

// contexts
import AuthenticationContext from "./contexts/Authentication.js";

function Authorize(props) {
  const pathQuery = queryString.parse(props.location.search);
  if (pathQuery.token) {
    localStorage.setItem("apiToken", pathQuery.token);
  }

  const { loginState } = useContext(AuthenticationContext);

  if (loginState == "unauthorized") {
    return <Redirect to="/error" />;
  }

  return props.children;
}

export default withRouter(Authorize);
