import React, { useEffect, useState } from "react";

import Layout from "../layouts/Layout";
import FormPayment from "../components/FormPayment.jsx";
import { PAYMENT_GATEWAY_API, KBANK_PKEY } from "../config";

export default function Payment() {
  const [inputCreditAmount, setInputCreditAmount] = useState("");

  const orderId = new Date().getTime();
  const driverToken = localStorage.getItem("apiToken");

  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://dev-kpaymentgateway.kasikornbank.com/ui/v2/kinlinepayment.min.js";
    script.async = true;

    script.setAttribute("data-apikey", KBANK_PKEY);
    script.setAttribute("data-lang", "TH");
    script.setAttribute("data-write-log", false);

    document.getElementById("form-kbank").appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Layout>
      <div class="label-my-credit">เพิ่มบัครเครดิต/เดบิต</div>

      <div class="payment-form">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label>ชื่อผู้ถือบัตร</label>
              <div id="card-name" class="form-control"></div>
            </div>

            <div class="form-group">
              <label>หมายเลขบัตร</label>
              <div id="card-number" class="form-control"></div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>วันหมดอายุ</label>
                  <div id="card-expiry" class="form-control"></div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>เลข CVV</label>
                  <div id="card-cvv" class="form-control"></div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div id="remember-card"></div>
            </div>
          </div>
        </div>
        <form
          method="POST"
          action={
            PAYMENT_GATEWAY_API +
            `/checkout?orderId=${orderId}&token=${driverToken}`
          }
          id="form-kbank"
        >
          <div class="footer">
            <FormPayment
              inputCreditAmount={inputCreditAmount}
              setInputCreditAmount={setInputCreditAmount}
            />
            <button class="btn btn-block btn-pay" type="submit">
              ชำระเงิน
            </button>
          </div>
        </form>
      </div>
      <a className="back" onClick={() => window.history.back()}>
        &#8592;
      </a>
    </Layout>
  );
}
