import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import $ from "jquery";
import _ from "lodash";
import { PAYMENT_GATEWAY_API } from "../config";

import Layout from "../layouts/Layout.jsx";
import FormPayment from "../components/FormPayment.jsx";

import AuthenticationContext from "../contexts/Authentication.js";

export default function Home() {
  const [credit, setCredit] = useState(0);
  const [cardList, setCardList] = useState(null);
  const [isLoadingCard, setIsLoadingCard] = useState(true);

  const [inputCreditAmount, setInputCreditAmount] = useState("");

  const { setLoginState } = useContext(AuthenticationContext);

  const apiToken = localStorage.getItem("apiToken");

  useEffect(() => {
    if (apiToken) {
      Axios.get(
        PAYMENT_GATEWAY_API + "/api/driver/get-credit?token=" + apiToken
      )
        .then(({ data }) => {
          setCredit(data.credit);
        })
        .catch((error) => {
          setLoginState("unauthorized");
        });

      Axios.get(PAYMENT_GATEWAY_API + "/api/driver/get-card?token=" + apiToken)
        .then(({ data }) => {
          setCardList([data.cardList[0]] || null);
          setIsLoadingCard(false);
        })
        .catch((error) => {
          setLoginState("unauthorized");
          setIsLoadingCard(false);
        });
    } else {
      setLoginState("unauthorized");
    }
  }, []);

  const handleSubmit = (e) => {
    if (
      isNaN(inputCreditAmount) ||
      !/^[0-9]+$|^[0-9]+\.+[0-9]{2}$/.test(inputCreditAmount)
    ) {
      alert("กรอกตัวเลขไม่ถูกต้อง");
      return e.preventDefault();
    }

    return true;
  };

  return (
    <Layout>
      <div class="label-my-credit">เติมเครดิต</div>
      <div class="wrap-my-credit">
        {!isLoadingCard && (
          <div class="box-deposit-credit">
            {cardList &&
              cardList.map((r) => (
                <div class="card-info">
                  <div class="brand">บัตร {r.brand}</div>
                  <div class="number">{r.number}</div>
                  <div class="name">{r.name}</div>
                </div>
              ))}

            {!cardList && (
              <div class="card-info">
                <div class="label">เพิ่มบัตร</div>
                <div>
                  <Link class="btn-add" to="/payment">
                    +
                  </Link>
                </div>
              </div>
            )}
            <div class="deposit-input-amount">
              <div class="label">เครดิต</div>
              <div class="value">
                <span id="">{credit}</span>฿
              </div>
            </div>
          </div>
        )}

        <div class="box-note">*** กด + เพื่อเพิ่มบัครที่จะชำระเงิน</div>
      </div>
      <div class="footer">
        {!_.isEmpty(cardList) && (
          <form
            method="post"
            action={PAYMENT_GATEWAY_API + `/payment/customer?token=${apiToken}`}
            onSubmit={handleSubmit}
          >
            <FormPayment
              inputCreditAmount={inputCreditAmount}
              setInputCreditAmount={setInputCreditAmount}
            />
            <input type="hidden" name="card" value={cardList[0].id || ""} />
            <button class="btn btn-block btn-pay" type="submit">
              ชำระเงิน
            </button>
          </form>
        )}
      </div>
      <a className="back" onClick={() => window.history.back()}>
        &#8592;
      </a>
    </Layout>
  );
}
