import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";

import Layout from "../layouts/Layout.jsx";

import AuthenticationContext from "../contexts/Authentication.js";

import { PAYMENT_GATEWAY_API } from "../config";

export default function Home() {
  const [credit, setCredit] = useState(0);
  const { setLoginState } = useContext(AuthenticationContext);

  // get credit
  useEffect(() => {
    const apiToken = localStorage.getItem("apiToken");
    if (apiToken) {
      Axios.get(
        PAYMENT_GATEWAY_API + "/api/driver/get-credit?token=" + apiToken
      )
        .then(({ data }) => {
          setCredit(data.credit);
        })
        .catch((error) => {
          setLoginState("unauthorized");
        });
    } else {
      setLoginState("unauthorized");
    }
  }, []);

  return (
    <Layout>
      <div class="label-my-credit">เครดิตรับงาน</div>
      <div class="wrap-my-credit">
        <div class="box-my-credit">{credit}฿</div>
        <div class="box-note">
          *** เครดิตต่ำกว่า 600 บาทจะไม่สามารถรับงานได้
        </div>
      </div>
      <div class="credit-manage">
        <Link to="/credit">
          <span>เติมเครดิตรับงาน</span> <span>&#8250;</span>
        </Link>
        <Link to="/history">
          <span>ประวัติเครดิต</span> <span>&#8250;</span>
        </Link>
      </div>
    </Layout>
  );
}
