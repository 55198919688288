import React, { useEffect, useState, useContext } from "react";
import moment from "moment";

import Layout from "../layouts/Layout";
import Axios from "axios";
import queryString from "query-string";

import AuthenticationContext from "../contexts/Authentication.js";

import { PAYMENT_GATEWAY_API } from "../config";

const toDateFormat = (dateObj) => moment(dateObj).format("DD/MM/YYYY HH:mm");
const toType = (type) => (type == 1 ? "" : "used");
const toTypeDes = (type, amount) => (type == 1 ? amount : "-" + amount);

const perPage = 20;

export default function History() {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [historyList, setHistoryList] = useState([]);
  const [filterList, setFilterList] = useState("all");
  const [filterMonth, setFilterMonth] = useState("this-month");

  const { setLoginState } = useContext(AuthenticationContext);

  const apiToken = localStorage.getItem("apiToken");

  useEffect(() => {
    if (apiToken) {
      const params = {
        token: apiToken,
        filter: filterList,
        month: filterMonth,
        items: page * perPage,
      };
      const queryAll = queryString.stringify(params);
      Axios.get(PAYMENT_GATEWAY_API + "/api/topup/history?" + queryAll)
        .then(({ data }) => {
          setHistoryList(data.transactions);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setLoginState("unauthorized");
    }
  }, [filterList, filterMonth, page]);

  return (
    <Layout>
      <div className="app-container">
        <div className="label-my-credit">ประวัติเครดิต</div>
        <div className="wrap-filter">
          <div className="history-filter">
            <div className="form-group">
              <select
                className="form-control"
                onChange={(e) => setFilterList(e.currentTarget.value)}
                value={filterList}
              >
                <option value="all">ทุกประเภท</option>
                <option value="topup">เติมเครดิต</option>
                <option value="used">ใช้เครดิต</option>
              </select>
            </div>
            <div className="form-group">
              <select
                className="form-control"
                onChange={(e) => setFilterMonth(e.currentTarget.value)}
                value={filterMonth}
              >
                <option value="this-month">เดือนนี้</option>
                <option value="prev-month">เดือนที่แล้ว</option>
              </select>
            </div>
          </div>
          <div className="history-list">
            {isLoading && <>Loading</>}
            {!isLoading &&
              historyList &&
              historyList.map((r) => {
                return (
                  <div key={r._id} className="history-item">
                    <div className="date-title">
                      <div className="date">{toDateFormat(r.dateCreated)}</div>
                      <div className="title">{r.message}</div>
                    </div>
                    <div className={"amount " + toType(r.type)}>
                      {toTypeDes(r.type, r.amount)}฿
                    </div>
                  </div>
                );
              })}
          </div>
          {!isLoading && historyList && (
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={(e) => setPage(page + 1)}
            >
              โหลดเพิ่ม
            </button>
          )}
        </div>
      </div>
      <a className="back" onClick={() => window.history.back()}>
        &#8592;
      </a>
    </Layout>
  );
}
