import React, { useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";

// pages
import Home from "./pages/Home.jsx";
import Credit from "./pages/Credit.jsx";
import Payment from "./pages/Payment.jsx";
import History from "./pages/History.jsx";

// contexts
import AuthenticationContext from "./contexts/Authentication.js";

import Authorize from "./Authorize";

function App() {
  const [loginState, setLoginState] = useState(null);

  return (
    <AuthenticationContext.Provider
      value={{
        loginState,
        setLoginState,
      }}
    >
      <BrowserRouter>
        <>
          <Authorize>
            <Route path="/" exact component={Home} />
            <Route path="/credit" exact component={Credit} />
            <Route path="/payment" exact component={Payment} />
            <Route path="/history" exact component={History} />
          </Authorize>
          <Route
            path="/error"
            exact
            component={() => {
              return <>404</>;
            }}
          />
        </>
      </BrowserRouter>
    </AuthenticationContext.Provider>
  );
}

export default App;
