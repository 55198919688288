import React from "react";

export default function FormPayment(props) {
  const { inputCreditAmount, setInputCreditAmount } = props;

  const handleAddCreditAmount = (amount) => {
    return () => {
      if (!isNaN(parseFloat(inputCreditAmount))) {
        setInputCreditAmount(parseFloat(inputCreditAmount) + amount);
        return;
      }

      setInputCreditAmount(amount);
    };
  };
  return (
    <>
      <div class="input-manual-credit">
        <input
          type="text"
          placeholder="จำนวนเครดิต(THB)"
          name="credit_amount"
          id="inputAmount"
          class="form-control"
          value={inputCreditAmount}
          pattern="[0-9]*"
          onChange={(e) => setInputCreditAmount(e.currentTarget.value)}
        />
      </div>
      <div class="choose-credit-list">
        <div class="item" onClick={handleAddCreditAmount(100)}>
          +100
        </div>
        <div class="item" onClick={handleAddCreditAmount(500)}>
          +500
        </div>
        <div class="item" onClick={handleAddCreditAmount(1000)}>
          +1,000
        </div>
      </div>
    </>
  );
}
